.causes-details-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
}

.causes-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
}

.causes-details_title {
  font-size: 18px;
  font-weight: 700;
}

.causes-details_desc {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.causes-details_btn {
  padding: 8px 20px;
  background: rgba(34, 34, 34, 1);
  color: #fff;
  border-radius: 30px;
  outline: none;
  border: 0.5px solid white;
  box-shadow: -3px 4px black;
  font-weight: 700;
}

.causes-image {
  width: 80px;
  border-radius: 10px;
}
